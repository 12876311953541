import * as React from "react";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import GeneralTab from "../../components/HTabs";
import Service from "../../components/Services";
import Document from "../../components/Document";
import About from "../../components/About";
// import Hero from "../../components/Hero";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function BusinessPlan() {
  /* Slider */
  // const BusinessPlanSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 60,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Business Plan`,
  //     content: `Advisory Service for Business Plan`,
  //     image: "/imgs/business/llp.png",
  //     alt_tag: "Best Online Advisory Service for Business Plan in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Advisory Service for Business Plan`,
  //     content: `Best Advisory Service for Business Plan Platform`,
  //     image: "/imgs/business/mettings.png",
  //     alt_tag: "Best Online Advisory Service for Business Plan  near Bangalore",
  //   },

  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 40,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Advisory Service for Business Plan in Hosur`,
  //     content: `One the top most success rated Advisory Service for Business Plan  in Hosur`,
  //     image: "/imgs/business/windingprocedure.png",
  //     alt_tag: "Best Online Advisory Service for Business Plan in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Business Plan`,
    buyBtnLink: `#pricing-buy`,
    price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };

  /* About Data Without Form*/
  const BusinessPlanAboutData = {
    title: `Business Plan`,
    sub_title: `Business plan advisory service in India`,
    paragraph: `All significant operational and financial goals of a new business are laid out
            in a business plan. It is a written forecast of the company's future and a guide
            to your company's success. Every company must create a business plan, whether
            it's to give the company direction or to draw in investors.Business plan is nothing more than a thorough 
            draught and budget that shows how to achieve the
            main goal being established for the company. The success of your firm is greatly
            influenced by your business strategy.`,
    header: `Importance of Business Plan`,
    points: [
      `The person who intends to launch a business need a business plan
                the person who does not intend to launch a business does not require a business plan,
                and the person who intends to pursue a normal employment or launch a hobby does not
                require a business plan.`,
    ],
  };
  /* Service Data */
  const BusinessPlanData = {
    header: `The components of a business strategy`,
    content: `It is always preferable to plan and design the fundamentals of
        a business, services or products, business marketing methods,
        and business goals if somebody is considering beginning a
        business.`,
    image: '/imgs/ddtypes.png',
    alt_img: 'TODAYFILINGS Business Plan Compliances Service',
    points: [
      `Market Strategies or Marketing plan`,
      `Competitive and Executive Research`,
      `Creating and refining the business plan`,
      `Company's product and service`,
      `Management and Operational Plan`,
      `Description and Financial Elements`,
    ],
  };
  /* Document Section Data */
  const BusinessPlanDocData = {
    id: 'bp-doc',
    heading: `Types of Business Plan`,
    sub_heading: `There is some essential types of business plans are,`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/it/startup.png`,
        alt_tag: `Startup Business Plan`,
        header: `Startup Business Plan`,
        description: `Startups Firm Valuation is a document that contains
            all of the processes required in the process of starting a business.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/it/internal-business.png`,
        alt_tag: `Internal Business Plan`,
        header: `Internal Business Plan`,
        description: `Internal business plans typically include a market analysis that explains the
            target market's size, demographics, and how they will increase sales.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/it/business-plan.png`,
        alt_tag: `Strategic Business Plan`,
        header: `Strategic Business Plan`,
        description: `Under the strategic business plan, a high-level vision
            of the company's aim and how that goal will be attained might be created.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/it/feasibility.png`,
        alt_tag: `Business plan for feasibility`,
        header: `Business plan for feasibility`,
        description: `Requirements for the service or product the company is
            offering, necessary capitals, and targeted demographics. Such business
            plans always result in moving forward.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/it/growth.png`,
        alt_tag: `Operational and Growth Business Plan`,
        header: `Operational Growth Business Plan`,
        description: `These company plans outline the internal strategies and operational
            components. outlined for the company's internal and external goals is
            growth business plan.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/it/selling.png`,
        alt_tag: `For Selling Business`,
        header: `For Selling Business`,
        description: `Business plan is essential when it comes to selling a company. You can
            explain what you have, its value, and the benefits of purchasing it to
            potential customers.`,
      },
    ],
  };
  /* FaQ Data */
  var BusinessPlanFAQ = [
    {
      header: 'What is a Business Plan?',
      body: [
        {
          content: `All significant operational and financial goals of a new business
                    are laid out in a business plan. It is a written forecast of the
                    company's future and a guide to your company's success.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Do I really need a business plan?',
      body: [
        {
          content: `Yes, you do. Even if you think you have a clear idea of how you are going to start 
          and develop your business, setting it out on paper forces you to be more objective and attentive 
          to details. It is human nature to tend to be optimistic about future schemes. However, the process 
          of drafting the plans will help you assess whether you have actually taken all relevant factors into 
          account, or your plan’s success depends on outcomes you have no way of assuring.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What should my business plan look like?',
      body: [
        {
          content: `There is not a specific format to follow or a particular length that your plan should be. 
          Most importantly, your plan should include all necessary information for you to use it as a tool for 
          assessing your progress. Typically, a plan will start by setting out your business goals. Next, it should
           provide a comprehensive picture of your current financial state, as well as solidly grounded projections
            for future costs and revenues. Following this, outline your current and future strategies, making sure 
            to provide information that will show that these strategies are feasible and will bring you closer to 
            meeting your goals.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Do I need a professional’s help to write a business plan?',
      body: [
        {
          content: `That will depend on your specific capabilities and background. Generally, it never hurts to have an 
          accountant review your financial information to be sure that your figures are right and your analysis is 
          correct. Finances can get complicated and errors are common, so a second look by a qualified accountant can 
          help eliminate mistakes. An attorney can also review your plan and point out potential legal issues that can 
          affect it, such as the feasibility of obtaining certain necessary permits or variances, the expense of 
          ensuring compliance with regulations and any current or upcoming changes in law that may affect your business 
          model. In addition, if you are planning to submit your plan to potential investors, it can help to have 
          a professional review it and ensure that professional writing and correct terminology is used throughout.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What if I want to make changes later?',
      body: [
        {
          content: `Circumstances change, and at some point you may wish to adapt your business plan to reflect those 
          changes. If your plan is just for internal use, there is nothing to stop you from changing and improving your
           plan; it is, in fact, good business sense to do so. If your plan was used in obtaining financial backing, 
           this issue can be more complicated, depending on the type and extent of changes you wish to make. Review 
           your financing agreement, and consult an attorney to determine your legal obligations to your investors.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How much would it cost to get a business plan done?',
      body: [
        {
          content: `TODAYFILINGS Experts have several business planning packages. Each package is developed keeping in mind 
          the scale of the business and the purpose of the business plan. We provide extremely competitive and 
          affordable pricing for business plans. Please feel free to check out our solutions and pricing.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'What format should my business plan have?',
      body: [
        {
          content: `There is no set business plan to adhere to; instead, everything
                    relies on the type of firm, capital investment, enterprise value,
                    target market, and other variables.`,
          icon: false,
        },
      ],
    },
    {
      header: `What constitutes the Business Plan's main sections?`,
      body: [
        {
          content: `Business Concept Section`,
          icon: true,
        },
        {
          content: `Market Concept Section`,
          icon: true,
        },
        {
          content: `Economic section`,
          icon: true,
        },
      ],
    },
  ];
  /* Tabs Data */
  const BusinessPlanTabsData = {
    id: 'features',
    heading: 'Importance Of Business Plan',
    paragraph: `The person who intends to launch a business is the one who need a business plan
        the person who does not intend to launch a business, as well as the person seeking
        a normal work or launching a hobby, do not require a business plan.Anyone starting or growing a 
        business that will require investment of time, money, or energy and anticipates profits should 
        create a business plan to ensure a successful operation.`,
    body: [
      {
        tab_title: 'StartUp Business',
        tab_content: [
          {
            content_title: `For Business start ups`,
            content_paragraph: [],
            points: [
              `Business plan aids the beginning entrepreneur in
                        considering all relevant business issues. The startup
                        focuses more on large concepts than performance or
                        execution, which is the major justification for creating a
                        business plan.`,
              `Addresses every aspect of running a company, serves as a
                        road map for expansion, and is a long-term way to carry
                        out daily operations.`,
            ],
          },
        ],
        content_img: '/imgs/assited/business-startup.png',
        alt_tag: 'TODAYFILINGS StartUp Business',
      },
      {
        tab_title: 'Competitors',
        tab_content: [
          {
            content_title: 'Business plans aid in attracting investors',
            content_paragraph: `An entrepreneur seeking funding for a project should
                    create a business plan that will persuade the investor.
                    Furthermore, it is beneficial to the company at all stages
                    of operation, whether they are looking for funding to
                    invest the excess.`,
            points: [],
          },
          {
            content_title: 'Successful and strategic exit',
            content_paragraph: `It is quite tough to value a company, but the business
                    plan may show all potential investments. Therefore, a
                    stronger valuation can result in a more profitable exit if
                    the venture owner decides at any moment to close the
                    business.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/profit.png',
        alt_tag: 'Best Business Plan Compliances Service in Hosur',
      },
      {
        tab_title: 'Administration',
        tab_content: [
          {
            content_title: 'Set goals for the management team and managers',
            content_paragraph: `If a company has a strong management team, it can run its
                    firm successfully. A strong business plan will be
                    beneficial in forming this team by helping with
                    goal-setting, tracking, and following procedures. The firm
                    management will attain the objectives in a more structured
                    manner if they adhere to the written business plan.`,
            points: [],
          },
          {
            content_title: 'Cash Flow Control',
            content_paragraph: `One of the key elements of any successful firm is
                    effective cash flow management. The whole company plan
                    will assist in managing present finance, cash input and
                    outflow, and future requirements.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/management.png',
        alt_tag: 'Best Business Plan Compliances near Bangalore',
      },
      {
        tab_title: 'Stock',
        tab_content: [
          {
            content_title: 'For the Development of Current Businesses',
            content_paragraph: `A business plan will assist in creating a marketing plan
                    and allocating resources in accordance with the strategic
                    priority.`,
            points: [],
          },
          {
            content_title: `To purchase Alliance`,
            content_paragraph: `A well-written business plan can be used by established
                    companies to prepare the objectives for new bonds, deals,
                    and the chosen component of your business plan to mediate
                    with those alliances.`,
            points: [],
          },
          {
            content_title: `Assessment of the Business`,
            content_paragraph: `The net worth of your company is the only item that is
                    mentioned in a valuation of business. The business plan
                    will give the specialists the opportunity to assess your
                    company's operations, timing, value, and potential for
                    profits or market demand.`,
            points: [],
          },
          {
            content_title: `To Promote Business`,
            content_paragraph: `An essential component of the trading firm is the business
                    plan. You can assist the buyers in comprehending what they
                    are selling, its value, and their motivation for
                    purchasing it.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/established.png',
        alt_tag: 'Best Business Plan Compliances near Vellore',
      },
    ],
  };
  return (
    <div>
      <Seo
        title='Business Plan'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={BusinessPlanSlider} /> */}

          <Package packageData={PackageCompData} />

          <About about_data={BusinessPlanAboutData} />

          <Counter />

          <Service item={BusinessPlanData} />

          <Document docSecData={BusinessPlanDocData} />

          <GeneralTab HTabsData={BusinessPlanTabsData} />
          <Cta />
          <FAQAccord items={BusinessPlanFAQ} />
        </main>
      </Layout>
    </div>
  );
}
